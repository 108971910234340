/**
 * Get List
 */
export const TEMP_ARTICLE_LIST = "TEMP_ARTICLE_LIST";
export const TEMP_ARTICLE_LIST_SUCCESS = "TEMP_ARTICLE_LIST_SUCCESS";
export const TEMP_ARTICLE_LIST_FAILURE = "TEMP_ARTICLE_LIST_FAILURE";

/**
 * CREATE
 */
export const TEMP_ARTICLE_CREATE = "TEMP_ARTICLE_CREATE";
export const TEMP_ARTICLE_CREATE_SUCCESS = "TEMP_ARTICLE_CREATE_SUCCESS";
export const TEMP_ARTICLE_CREATE_FAILURE = "TEMP_ARTICLE_CREATE_FAILURE";

/**
 * GET BY ID
 */
export const TEMP_ARTICLE_GET = "TEMP_ARTICLE_GET";
export const TEMP_ARTICLE_GET_SUCCESS = "TEMP_ARTICLE_GET_SUCCESS";
export const TEMP_ARTICLE_GET_FAILURE = "TEMP_ARTICLE_GET_FAILURE";

/**
 * UPDATE BY ID
 */
export const TEMP_ARTICLE_UPDATE = "TEMP_ARTICLE_UPDATE";
export const TEMP_ARTICLE_UPDATE_SUCCESS = "TEMP_ARTICLE_UPDATE_SUCCESS";
export const TEMP_ARTICLE_UPDATE_FAILURE = "TEMP_ARTICLE_UPDATE_FAILURE";

/**
 * DELETE BY ID
 */
export const TEMP_ARTICLE_DELETE = "TEMP_ARTICLE_DELETE";
export const TEMP_ARTICLE_DELETE_SUCCESS = "TEMP_ARTICLE_DELETE_SUCCESS";
export const TEMP_ARTICLE_DELETE_FAILURE = "TEMP_ARTICLE_DELETE_FAILURE";