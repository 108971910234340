
export const GET_JOURNAL_PROFILE = "admin/GET_JOURNAL_PROFILE";
export const GET_JOURNAL_PROFILE_SUCCESS = "admin/GET_JOURNAL_PROFILE_SUCCESS";
export const GET_JOURNAL_PROFILE_FAILURE = "admin/GET_JOURNAL_PROFILE_FAILURE";

export const UPDATE_JOURNAL_PROFILE = "admin/UPDATE_JOURNAL_PROFILE";
export const UPDATE_JOURNAL_PROFILE_SUCCESS = "admin/UPDATE_JOURNAL_PROFILE_SUCCESS";
export const UPDATE_JOURNAL_PROFILE_FAILURE = "admin/UPDATE_JOURNAL_PROFILE_FAILURE";

export const GET_PAGES = "admin/GET_PAGES";
export const GET_PAGES_SUCCESS = "admin/GET_PAGES_SUCCESS";
export const GET_PAGES_FAILURE = "admin/GET_PAGES_FAILURE";


