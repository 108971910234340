/**
 * Get List
 */
export const JOURNAL_LIST = "JOURNAL_LIST";
export const JOURNAL_LIST_SUCCESS = "JOURNAL_LIST_SUCCESS";
export const JOURNAL_LIST_FAILURE = "JOURNAL_LIST_FAILURE";

/**
 * CREATE
 */
export const JOURNAL_CREATE = "JOURNAL_CREATE";
export const JOURNAL_CREATE_SUCCESS = "JOURNAL_CREATE_SUCCESS";
export const JOURNAL_CREATE_FAILURE = "JOURNAL_CREATE_FAILURE";

/**
 * GET BY ID
 */
export const JOURNAL_GET = "JOURNAL_GET";
export const JOURNAL_GET_SUCCESS = "JOURNAL_GET_SUCCESS";
export const JOURNAL_GET_FAILURE = "JOURNAL_GET_FAILURE";

/**
 * UPDATE BY ID
 */
export const JOURNAL_UPDATE = "JOURNAL_UPDATE";
export const JOURNAL_UPDATE_SUCCESS = "JOURNAL_UPDATE_SUCCESS";
export const JOURNAL_UPDATE_FAILURE = "JOURNAL_UPDATE_FAILURE";

/**
 * DELETE BY ID
 */
export const JOURNAL_DELETE = "JOURNAL_DELETE";
export const JOURNAL_DELETE_SUCCESS = "JOURNAL_DELETE_SUCCESS";
export const JOURNAL_DELETE_FAILURE = "JOURNAL_DELETE_FAILURE";