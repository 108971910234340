/**
 * Get List
 */
export const JOURNAL_CATEGORY_LIST = "JOURNAL_CATEGORY_LIST";
export const JOURNAL_CATEGORY_LIST_SUCCESS = "JOURNAL_CATEGORY_LIST_SUCCESS";
export const JOURNAL_CATEGORY_LIST_FAILURE = "JOURNAL_CATEGORY_LIST_FAILURE";

/**
 * Create
 */
export const JOURNAL_CATEGORY_CREATE = "JOURNAL_CATEGORY_CREATE";
export const JOURNAL_CATEGORY_CREATE_SUCCESS = "JOURNAL_CATEGORY_CREATE_SUCCESS";
export const JOURNAL_CATEGORY_CREATE_FAILURE = "JOURNAL_CATEGORY_CREATE_FAILURE";

/**
 * GET BY ID
 */
export const JOURNAL_CATEGORY_GET = "JOURNAL_CATEGORY_GET";
export const JOURNAL_CATEGORY_GET_SUCCESS = "JOURNAL_CATEGORY_GET_SUCCESS";
export const JOURNAL_CATEGORY_GET_FAILURE = "JOURNAL_CATEGORY_GET_FAILURE";

/**
 * Update
 */
export const JOURNAL_CATEGORY_UPDATE = "JOURNAL_CATEGORY_UPDATE";
export const JOURNAL_CATEGORY_UPDATE_SUCCESS = "JOURNAL_CATEGORY_UPDATE_SUCCESS";
export const JOURNAL_CATEGORY_UPDATE_FAILURE = "JOURNAL_CATEGORY_UPDATE_FAILURE";

/**
 * DELETE BY ID
 */
export const JOURNAL_CATEGORY_DELETE = "JOURNAL_CATEGORY_DELETE";
export const JOURNAL_CATEGORY_DELETE_SUCCESS = "JOURNAL_CATEGORY_DELETE_SUCCESS";
export const JOURNAL_CATEGORY_DELETE_FAILURE = "JOURNAL_CATEGORY_DELETE_FAILURE";

