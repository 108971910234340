//WEB PAGE LIST
export const WEBPAGE_LIST = "WEBPAGE_LIST";
export const WEBPAGE_LIST_SUCCESS = "WEBPAGE_LIST_SUCCESS";
export const WEBPAGE_LIST_FAILURE = "WEBPAGE_LIST_FAILURE";


/**
 * GET BY ID
 */
export const WEBPAGE_GET = "WEBPAGE_GET";
export const WEBPAGE_GET_SUCCESS = "WEBPAGE_GET_SUCCESS";
export const WEBPAGE_GET_FAILURE = "WEBPAGE_GET_FAILURE";

/**
 * UPDATE BY ID
 */
export const WEBPAGE_UPDATE = "WEBPAGE_UPDATE";
export const WEBPAGE_UPDATE_SUCCESS = "WEBPAGE_UPDATE_SUCCESS";
export const WEBPAGE_UPDATE_FAILURE = "WEBPAGE_UPDATE_FAILURE";
