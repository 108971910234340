/**
 * Get List
 */
export const GET_MAIN_PAGE_DETAIL = "web/GET_MAIN_PAGE_DETAIL";
export const GET_MAIN_PAGE_DETAIL_SUCCESS = "web/GET_MAIN_PAGE_DETAIL_SUCCESS";
export const GET_MAIN_PAGE_DETAIL_FAILURE = "web/GET_MAIN_PAGE_DETAIL_FAILURE";


export const GET_OTHER_PAGE_DETAIL = "web/GET_OTHER_PAGE_DETAIL";
export const GET_OTHER_PAGE_DETAIL_SUCCESS = "web/GET_OTHER_PAGE_DETAIL_SUCCESS";
export const GET_OTHER_PAGE_DETAIL_FAILURE = "web/GET_OTHER_PAGE_DETAIL_FAILURE";