/**
 * CREATE
 */
export const ADMIN_ARTICLE_CREATE = "admin/ARTICLE_CREATE";
export const ADMIN_ARTICLE_CREATE_SUCCESS = "admin/ARTICLE_CREATE_SUCCESS";
export const ADMIN_ARTICLE_CREATE_FAILURE = "admin/ARTICLE_CREATE_FAILURE";

/**
 * DELETE ARTICLE
 */
export const ADMIN_ARTICLE_DELETE = "admin/ADMIN_ARTICLE_DELETE";
export const ADMIN_ARTICLE_DELETE_SUCCESS = "admin/ADMIN_ARTICLE_DELETE_SUCCESS";
export const ADMIN_ARTICLE_DELETE_FAILURE = "admin/ADMIN_ARTICLE_DELETE_FAILURE";

/**
 * Get List
 */
export const JOURNAL_ARTICLE_LIST = "JOURNAL_ARTICLE_LIST";
export const JOURNAL_ARTICLE_LIST_SUCCESS = "JOURNAL_ARTICLE_LIST_SUCCESS";
export const JOURNAL_ARTICLE_LIST_FAILURE = "JOURNAL_ARTICLE_LIST_FAILURE";


/**
 * Get detail by
 */
export const JOURNAL_ARTICLE_DETAIL = "JOURNAL_ARTICLE_DETAIL";
export const JOURNAL_ARTICLE_DETAIL_SUCCESS = "JOURNAL_ARTICLE_DETAIL_SUCCESS";
export const JOURNAL_ARTICLE_DETAIL_FAILURE = "JOURNAL_ARTICLE_DETAIL_FAILURE";

/**
 * UPDATE STATUS
 */
export const JOURNAL_ARTICLE_UPDATE_STATUS = "JOURNAL_ARTICLE_UPDATE_STATUS";
export const JOURNAL_ARTICLE_UPDATE_STATUS_SUCCESS = "JOURNAL_ARTICLE_UPDATE_STATUS_SUCCESS";
export const JOURNAL_ARTICLE_UPDATE_STATUS_FAILURE = "JOURNAL_ARTICLE_UPDATE_STATUS_FAILURE";


/**
 * CREATE
 */
export const ARTICLE_PUBLISH_CREATE = "ARTICLE_PUBLISH_CREATE";
export const ARTICLE_PUBLISH_CREATE_SUCCESS = "ARTICLE_PUBLISH_CREATE_SUCCESS";
export const ARTICLE_PUBLISH_CREATE_FAILURE = "ARTICLE_PUBLISH_CREATE_FAILURE";


/**
 * Update article published
 */
export const ARTICLE_PUBLISH_UPDATE = "ARTICLE_PUBLISH_UPDATE";
export const ARTICLE_PUBLISH_UPDATE_SUCCESS = "ARTICLE_PUBLISH_UPDATE_SUCCESS";
export const ARTICLE_PUBLISH_UPDATE_FAILURE = "ARTICLE_PUBLISH_UPDATE_FAILURE";


/**
 * Upload file get all
 */
export const GET_ARTICLE_FILE_UPLOAD = "GET_ARTICLE_FILE_UPLOAD";
export const GET_ARTICLE_FILE_UPLOAD_SUCCESS = "GET_ARTICLE_FILE_UPLOAD_SUCCESS";
export const GET_ARTICLE_FILE_UPLOAD_FAILURE = "GET_ARTICLE_FILE_UPLOAD_FAILURE";


/**
 * Upload file
 */
export const ARTICLE_FILE_UPLOAD = "ARTICLE_FILE_UPLOAD";
export const ARTICLE_FILE_UPLOAD_SUCCESS = "ARTICLE_FILE_UPLOAD_SUCCESS";
export const ARTICLE_FILE_UPLOAD_FAILURE = "ARTICLE_FILE_UPLOAD_FAILURE";

/**
 * Upload file Delete
 */
export const ARTICLE_FILE_UPLOAD_DELETE = "ARTICLE_FILE_UPLOAD_DELETE";
export const ARTICLE_FILE_UPLOAD_DELETE_SUCCESS = "ARTICLE_FILE_UPLOAD_DELETE_SUCCESS";
export const ARTICLE_FILE_UPLOAD_DELETE_FAILURE = "ARTICLE_FILE_UPLOAD_DELETE_FAILURE";

