/**
 * Get List
 */
export const INDEXING_LIST = "INDEXING_LIST";
export const INDEXING_LIST_SUCCESS = "INDEXING_LIST_SUCCESS";
export const INDEXING_LIST_FAILURE = "INDEXING_LIST_FAILURE";

/**
 * CREATE
 */
export const INDEXING_CREATE = "INDEXING_CREATE";
export const INDEXING_CREATE_SUCCESS = "INDEXING_CREATE_SUCCESS";
export const INDEXING_CREATE_FAILURE = "INDEXING_CREATE_FAILURE";

/**
 * GET BY ID
 */
export const INDEXING_GET = "INDEXING_GET";
export const INDEXING_GET_SUCCESS = "INDEXING_GET_SUCCESS";
export const INDEXING_GET_FAILURE = "INDEXING_GET_FAILURE";

/**
 * UPDATE BY ID
 */
export const INDEXING_UPDATE = "INDEXING_UPDATE";
export const INDEXING_UPDATE_SUCCESS = "INDEXING_UPDATE_SUCCESS";
export const INDEXING_UPDATE_FAILURE = "INDEXING_UPDATE_FAILURE";

/**
 * DELETE BY ID
 */
export const INDEXING_DELETE = "INDEXING_DELETE";
export const INDEXING_DELETE_SUCCESS = "INDEXING_DELETE_SUCCESS";
export const INDEXING_DELETE_FAILURE = "INDEXING_DELETE_FAILURE";