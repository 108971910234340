/**
 * Get List
 */
export const ARTICLE_LIST = "ARTICLE_LIST";
export const ARTICLE_LIST_SUCCESS = "ARTICLE_LIST_SUCCESS";
export const ARTICLE_LIST_FAILURE = "ARTICLE_LIST_FAILURE";

/**
 * CREATE
 */
export const ARTICLE_CREATE = "ARTICLE_CREATE";
export const ARTICLE_CREATE_SUCCESS = "ARTICLE_CREATE_SUCCESS";
export const ARTICLE_CREATE_FAILURE = "ARTICLE_CREATE_FAILURE";

/**
 * GET BY ID
 */
export const ARTICLE_GET = "ARTICLE_GET";
export const ARTICLE_GET_SUCCESS = "ARTICLE_GET_SUCCESS";
export const ARTICLE_GET_FAILURE = "ARTICLE_GET_FAILURE";

/**
 * UPDATE BY ID
 */
export const ARTICLE_UPDATE = "ARTICLE_UPDATE";
export const ARTICLE_UPDATE_SUCCESS = "ARTICLE_UPDATE_SUCCESS";
export const ARTICLE_UPDATE_FAILURE = "ARTICLE_UPDATE_FAILURE";

/**
 * DELETE BY ID
 */
export const ARTICLE_DELETE = "ARTICLE_DELETE";
export const ARTICLE_DELETE_SUCCESS = "ARTICLE_DELETE_SUCCESS";
export const ARTICLE_DELETE_FAILURE = "ARTICLE_DELETE_FAILURE";