/**
 * Get List
 */
export const WEB_SETTING_DETAIL = "web/WEB_SETTING_DETAIL";
export const WEB_SETTING_DETAIL_SUCCESS = "web/WEB_SETTING_DETAIL_SUCCESS";
export const WEB_SETTING_DETAIL_FAILURE = "web/WEB_SETTING_DETAIL_FAILURE";

//UPDATE
export const WEB_SETTING_UPDATE = "web/WEB_SETTING_UPDATE";
export const WEB_SETTING_UPDATE_SUCCESS = "web/WEB_SETTING_UPDATE_SUCCESS";
export const WEB_SETTING_UPDATE_FAILURE = "web/WEB_SETTING_UPDATE_FAILURE";
