export const GET_OTHER_PAGES = "super_admin/GET_OTHER_PAGES";
export const GET_OTHER_PAGES_SUCCESS = "super_admin/GET_OTHER_PAGES_SUCCESS";
export const GET_OTHER_PAGES_FAILURE = "super_admin/GET_OTHER_PAGES_FAILURE";

export const GET_OTHER_PAGE_DETAIL = "super_admin/GET_OTHER_PAGE_DETAIL";
export const GET_OTHER_PAGE_DETAIL_SUCCESS = "super_admin/GET_OTHER_PAGE_DETAIL_SUCCESS";
export const GET_OTHER_PAGE_DETAIL_FAILURE = "super_admin/GET_OTHER_PAGE_DETAIL_FAILURE";

//update
export const UPDATE_OTHER_PAGE_DETAIL = "super_admin/UPDATE_OTHER_PAGE_DETAIL";
export const UPDATE_OTHER_PAGE_DETAIL_SUCCESS = "super_admin/UPDATE_OTHER_PAGE_DETAIL_SUCCESS";
export const UPDATE_OTHER_PAGE_DETAIL_FAILURE = "super_admin/UPDATE_OTHER_PAGE_DETAIL_FAILURE";

