/**
 * Get List
 */
export const JOURNAL_USERS = "JOURNAL_USERS";
export const JOURNAL_USERS_SUCCESS = "JOURNAL_USERS_SUCCESS";
export const JOURNAL_USERS_FAILURE = "JOURNAL_USERS_FAILURE";

/**
 * CREATE
 */
export const JOURNAL_USER_CREATE = "JOURNAL_USER_CREATE";
export const JOURNAL_USER_CREATE_SUCCESS = "JOURNAL_USER_CREATE_SUCCESS";
export const JOURNAL_USER_CREATE_FAILURE = "JOURNAL_USER_CREATE_FAILURE";

/**
 * DELETE BY ID
 */
export const JOURNAL_USER_DELETE = "JOURNAL_USER_DELETE";
export const JOURNAL_USER_DELETE_SUCCESS = "JOURNAL_USER_DELETE_SUCCESS";
export const JOURNAL_USER_DELETE_FAILURE = "JOURNAL_USER_DELETE_FAILURE";