export const GET_ADMIN_JOURNAL_PAGES = "admin/GET_ADMIN_JOURNAL_PAGES";
export const GET_ADMIN_JOURNAL_PAGES_SUCCESS = "admin/GET_ADMIN_JOURNAL_PAGES_SUCCESS";
export const GET_ADMIN_JOURNAL_PAGES_FAILURE = "admin/GET_ADMIN_JOURNAL_PAGES_FAILURE";

export const GET_ADMIN_JOURNAL_PAGE_DETAIL = "admin/GET_ADMIN_JOURNAL_PAGE_DETAIL";
export const GET_ADMIN_JOURNAL_PAGE_DETAIL_SUCCESS = "admin/GET_ADMIN_JOURNAL_PAGE_DETAIL_SUCCESS";
export const GET_ADMIN_JOURNAL_PAGE_DETAIL_FAILURE = "admin/GET_ADMIN_JOURNAL_PAGE_DETAIL_FAILURE";

//update
export const UPDATE_ADMIN_JOURNAL_PAGE_DETAIL = "admin/UPDATE_ADMIN_JOURNAL_PAGE_DETAIL";
export const UPDATE_ADMIN_JOURNAL_PAGE_DETAIL_SUCCESS = "admin/UPDATE_ADMIN_JOURNAL_PAGE_DETAIL_SUCCESS";
export const UPDATE_ADMIN_JOURNAL_PAGE_DETAIL_FAILURE = "admin/UPDATE_ADMIN_JOURNAL_PAGE_DETAIL_FAILURE";

