/**
 * Get List
 */
export const GET_AUTHOR_PROFILE = "author/GET_AUTHOR_PROFILE";
export const GET_AUTHOR_PROFILE_SUCCESS = "author/GET_AUTHOR_PROFILE_SUCCESS";
export const GET_AUTHOR_PROFILE_FAILURE = "author/GET_AUTHOR_PROFILE_FAILURE";

/**
 * Update
 */
export const UPDATE_AUTHOR_PROFILE = "author/UPDATE_AUTHOR_PROFILE";
export const UPDATE_AUTHOR_PROFILE_SUCCESS = "author/UPDATE_AUTHOR_PROFILE_SUCCESS";
export const UPDATE_AUTHOR_PROFILE_FAILURE = "author/UPDATE_AUTHOR_PROFILE_FAILURE";
